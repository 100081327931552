import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useMessage } from '../../../hooks/useMessage'
import { useSelector } from 'react-redux'
import Library from '../../../Library'
import DateInput from '../../../components/Inputs/DateInput'
import Textarea from '../../../components/Inputs/Textarea'
import ButtonsBlock from '../../../components/ButtonsBlock/ButtonsBlock'
import styled from 'styled-components'
import LinearLoader from '../../../components/Loaders/LinearLoader'
import moment from 'moment'
import Select from '../../../components/Inputs/Select'
import NumberInput from '../../../components/Inputs/NumberInput'
import useWindowHeight from '../../../hooks/useWindowHeight'
import { useLanguage } from '../../../context/LanguageContext'

const Wrapper = styled.div`
  position: relative;
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;

  @media (max-width: 649px) { 
    max-width: 300px;
    margin: 35px auto 85px auto;
  }

  @media (min-width: 650px) {
    margin: 35px 15px 10px 25px;
  }
`

const SectionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  column-gap: 10px;
  row-gap: 25px;
  max-width: ${({ $maxWidth }) => $maxWidth}px;
  @media (max-width: 649px) {  
    row-gap: 30px;
  }
`

const RowWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 300px;
  flex-wrap: nowrap;
  gap: 10px;
`

const CommentWrapper = styled.div`
  width: 100%;
  @media (max-width: 649px) { 
    margin-bottom: 30px;
  }
`

export const Form = () => {
  const location = useLocation()
  const pageName = location.pathname.substring(1).split('/')[0]
  const params = useParams()
  const message = useMessage()
  const { language } = useLanguage()
  const isDataLoaded = useSelector((state) => state.loading.isDataLoaded)
  const wrapperRef = useRef(null)
  useWindowHeight(wrapperRef, 84)
  const debounceDelay = 500
  const { companyId } = useSelector(state => state.data)
  const [dataReady, setDataReady] = useState(false)
  const initialFormData = useMemo(() => ({
    id: params.id,
    date: '',
    employee: '',
    period: '',
    amount: null,
    comment: '',
  }), [params.id])
  const [form, setForm] = useState(initialFormData)

  const { employees, employeesSalaries, loading, error } = useSelector(state => state.data)

  const [employeeSelectOptions, setEmployeeSelectOptions] = useState([])

  useEffect(() => {
    if (isDataLoaded && !loading && !error) {
      if (params.id && employeesSalaries.length) {
        const formData = Library.findItemById(employeesSalaries, params.id)
        if (formData) {
          setForm(formData)
        } else {
          setForm(initialFormData)
        }
      }
      setDataReady(true)
    }
  }, [initialFormData, isDataLoaded, loading, error, employeesSalaries, params.id])

  useEffect(() => {
    let obj = {}
    employeesSalaries.forEach((item) => {
      obj[item.budgetItem] = null
    })
  }, [employeesSalaries])

  const getSelectOptions = useCallback((data) => {
    let arr = [
      {
        value: '',
        label: '-',
        defaultValue: true,
        disabled: false,
      },
    ]
    if (data && data.length) {
      data.forEach((item) => {
        arr.push({ value: item.id, label: item.name })
      })
    }
    return arr
  }, [])

  useEffect(() => {
    if (employeesSalaries.length > 0) {
      let arr = []
      employeesSalaries.forEach((item) => {
        arr.push(item.budgetItem)
      })
    }
  }, [employeesSalaries])

  useEffect(() => {
    if (dataReady) {
      setEmployeeSelectOptions(getSelectOptions(employees))
    }
  }, [dataReady, params.id, employees, getSelectOptions])

  //------- CHANGE HISTORY -------//

  const keyTranslation = useMemo(() => [
    { id: 'id', name: 'id' },
    { id: 'timestamp', name: 'timestamp' },
    { id: 'status', name: 'Статус' },
    { id: 'date', name: 'Дата' },
    { id: 'employee', name: 'Сотрудник' },
    { id: 'period', name: 'Период' },
    { id: 'amount', name: 'Сумма' },
    { id: 'comment', name: 'Комментарий' },
    { id: 'lastModifiedBy', name: 'Кто изменил' },
  ], [])

  const formatValue = (key, value) => {
    switch (key) {
      case 'status': {
        return value === 'active' ? 'активный' : 'помечен на удаление'
      }
      case 'employee': {
        return Library.findNameByKey(employees, value)
      }
      case 'date': {
        return Library.formatDate(value, 'DD.MM.yyyy')
      }
      case 'period': {
        return Library.replacePeriod(value)
      }
      default: {
        return value
      }
    }
  }

  const getKeyTranslation = keyTranslation.reduce((obj, item) => {
    obj[item.id] = item.name
    return obj
  }, {})

  const renderChangeDetails = (change) => {
    if (!change.newData) {
      return ''
    }

    const parseJSON = (data) => {
      try {
        return JSON.parse(data.replace(/\n/g, '\\n'))
      } catch (e) {
        console.error("Error parsing JSON: ", e)
        return {}
      }
    }

    const isValueEmpty = (value) => {
      return value === '' || value === null || value === 0
    }

    const isValueChanged = (oldValue, newValue) => {
      return oldValue !== newValue
    }

    const parsedNewData = parseJSON(change.newData.replace(/\n/g, '\\n'))
    const parsedOldData = change.oldData ? parseJSON(change.oldData.replace(/\n/g, '\\n')) : {}

    return Object.keys(parsedNewData).map((key) => {
      let oldData = parsedOldData[key] ? formatValue(key, parsedOldData[key]) : ''
      let newData = parsedNewData[key] ? formatValue(key, parsedNewData[key]) : ''

      if ((isValueEmpty(oldData) && isValueEmpty(newData)) || !isValueChanged(oldData, newData)) {
        return null
      }

      return (
        <div key={key} style={{ marginBottom: '10px' }}>
          <span>{getKeyTranslation[key] || key}:</span>
          {'\u00A0'}
          <span>{oldData ? oldData : <span style={{ color: '#c3c3c3' }}>{language === 'ru' ? 'Пустое значение' : 'Blank value'}</span>}</span>
          {'\u00A0\u2192\u00A0'}
          <span>{newData ? newData : <span style={{ color: '#c3c3c3' }}>{language === 'ru' ? 'Пустое значение' : 'Blank value'}</span>}</span>
        </div>
      )
    }).filter(Boolean)
  }

  return (
    <Wrapper ref={wrapperRef}>
      <LinearLoader
        isLoading={!dataReady || !isDataLoaded}
        style={{ height: '4px' }}
      />
      {isDataLoaded && dataReady &&
        <FormWrapper>
          <SectionWrapper $maxWidth={700}>
            <RowWrapper>
              {/* ------- DATE ------- */}
              <DateInput
                label='Дата'
                name='date'
                id='date'
                value={form.date ? moment(form.date).format('yyyy-MM-DD') : ''}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm)
                }}
              />
            </RowWrapper>
            <RowWrapper>
              {/* ------- EMPLOYEE ------- */}
              < Select
                label={'Сотрудник'}
                id='employee'
                name='employee'
                value={form.employee}
                options={employeeSelectOptions}
                searchable={true}
                disabled={false}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm)
                }}
              />
            </RowWrapper>
            <RowWrapper>
              {/* ------- PERIOD ------- */}
              <DateInput
                label='Период'
                name='period'
                id='period'
                type='month'
                value={form.period}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm)
                }}
              />
            </RowWrapper>
            <RowWrapper>
              {/* ------- AMOUNT ------- */}
              <NumberInput
                label='Сумма'
                name='amount'
                id='amount'
                value={form.amount === null ? '' : form.amount}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm)
                }}
                debounceDelay={debounceDelay}
                readOnly={false}
              />
            </RowWrapper>
            {/* ------- COMMENT ------- */}
            <CommentWrapper>
              <RowWrapper>
                <Textarea
                  label='Комментарий'
                  name='comment'
                  id='comment'
                  value={form.comment}
                  debounceDelay={debounceDelay}
                  onChange={(e) => {
                    Library.changeHandler(e, form, setForm)
                  }}
                />
              </RowWrapper>
            </CommentWrapper>
          </SectionWrapper>
          <ButtonsBlock
            handler={Library.handler}
            form={form}
            edit={params.id ? true : false}
            label={form.date ? moment(form.date).format('DD.MM.yyyy') : ''}
            message={message}
            fileFolder={pageName}
            path='data'
            request={pageName}
            changeHistoryTableName={`${companyId}_change_history`}
            changeHistoryId={form.id}
            renderChangeDetails={renderChangeDetails}
          />
        </FormWrapper>
      }
    </Wrapper>
  )
}
