import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Library from '../../Library'
import LinearLoader from '../../components/Loaders/LinearLoader'
import Footer from '../../components/Footer'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import ListScrollAllSides from '../../components/ListScrollAllSides'
import Button from '../../components/Buttons/Button'
import moment from 'moment'
import ResultInput from '../../components/Inputs/ResultInput'
import VirtualizedList from '../../components/VirtualizedLists/VirtualizedList'
import useTranslate from '../../hooks/useTranslate'
import styled from 'styled-components'
import FilterButton from '../../components/ListFilter/components/FilterButton'

const ResultInputWrapper = styled.div`
  margin-left: 10px;
  min-width: 115px;
  max-width: 115px;
`

export const Main = () => {
  const location = useLocation()
  const pageName = location.pathname.substring(1)
  const translate = useTranslate()
  const router = useNavigate()
  const virtualizedListRefs = {
    listRef: useRef(),
    headerRef: useRef()
  }
  const isDataLoaded = useSelector((state) => state.loading.isDataLoaded)
  const mainState = useSelector(state => state.main)
  const pageState = useSelector(state => state.page[pageName])
  const [filterQuery, setFilterQuery] = useState(pageState.filterQuery)
  const [totalSparePartsCost, setTotalSparePartsCost] = useState(0)
  const [totalWorksCost, setTotalWorksCost] = useState(0)

  useEffect(() => {
    setFilterQuery(pageState.filterQuery)
  }, [pageState.filterQuery])

  const headers = useMemo(() => [
    { id: 'id', name: 'id', width: 275, hidden: true },
    { id: 'timestamp', name: 'timestamp', dataType: 'date', type: 'timestamp', width: 165, hidden: true },
    { id: 'number', name: '№', dataType: 'number', type: 'increment', width: 70 },
    { id: 'status', name: 'Статус', type: 'status', width: 60 },
    { id: 'date', name: 'Дата', dataType: 'date', type: 'date', width: 80 },
    { id: 'car', name: 'Автомобиль', width: 150 },
    { id: 'mileage', name: 'Пробег', dataType: 'number', width: 100 },
    { id: 'inventory', name: 'Номенклатура', width: 250 },
    { id: 'partNumber', name: 'Номер детали', width: 150 },
    { id: 'manufacturer', name: 'Производитель', width: 150 },
    { id: 'supplier', name: 'Поставщик/исполнитель', width: 150 },
    { id: 'sparePartCost', name: 'Стоимость детали', dataType: 'number', width: 100 },
    { id: 'sparePartPayType', name: 'Форма оплаты', width: 60 },
    { id: 'serviceProvider', name: 'Исполнитель', width: 150 },
    { id: 'completedWorksList', name: 'Выполненные работы', width: 150 },
    { id: 'workCost', name: 'Стоимость работ', dataType: 'number', width: 100 },
    { id: 'workPayType', name: 'Форма оплаты', width: 60 },
    { id: 'comment', name: 'Комментарий', width: 355 },
    { id: 'lastModifiedBy', name: 'Кто изменил', width: 200 },
    { id: 'lastModified', name: 'Дата изменения', dataType: 'date', type: 'timestamp', width: 140 },
  ].map(header => {
    if (!header.width) {
      header.width = 100
    }
    if (!header.dataType) {
      header.dataType = 'string'
    }
    return header
  }), [])

  const { cars, suppliers, inventory, maintenance, loading, error } = useSelector(state => state.data)
  const [dataReady, setDataReady] = useState(false)
  const [maintenanceReplaced, setMaintetanceReplaced] = useState([])

  const filteredTableData = useMemo(() => {
    const { search, status, startDate, endDate } = filterQuery

    const matchesSearch = (item) =>
      !search || Object.values(item)
        .toString()
        .toLowerCase()
        .includes(search.toLowerCase())

    const matchesStatus = (item) =>
      !status || item.status === status

    const matchesStartDate = (item) =>
      !startDate || moment(item.date).isSameOrAfter(startDate, 'day')

    const matchesEndDate = (item) =>
      !endDate || moment(item.date).isSameOrBefore(endDate, 'day')

    const filtered = maintenanceReplaced.filter(
      (item) =>
        matchesSearch(item) &&
        matchesStatus(item) &&
        matchesStartDate(item) &&
        matchesEndDate(item)
    )

    pageState.sort.key && Library.sort(filtered, pageState.sort)

    return filtered
  }, [maintenanceReplaced, filterQuery, pageState.sort])

  useEffect(() => {
    Library.activeTabScroll(mainState)
  }, [maintenanceReplaced, mainState])

  //======NEW ENTRY======//
  const newEntry = useCallback(() => {
    router(`/${pageName}/new`)
  }, [router, pageName])

  useEffect(() => {
    if (!isDataLoaded || loading || error) return
    setDataReady(true)
  }, [isDataLoaded, loading, error])

  useEffect(() => {
    if (!dataReady || loading || error) {
      return
    }
    let replaced = []
    maintenance.forEach((originalItem) => {
      let item = { ...originalItem }
      const inventoryItem = Library.findItemById(inventory, item.inventory)
      item['inventory'] = inventoryItem ? inventoryItem.name : ''
      item['partNumber'] = inventoryItem ? inventoryItem.partNumber : ''
      item['manufacturer'] = inventoryItem ? inventoryItem.manufacturer : ''
      item['supplier'] = Library.findNameByKey(suppliers, item.supplier)
      item['serviceProvider'] = Library.findNameByKey(suppliers, item.serviceProvider)
      item['car'] = Library.findNameByKey(cars, item.car)
      replaced.push(item)
    })
    setMaintetanceReplaced(replaced)
  }, [loading, error, dataReady, inventory, maintenance, cars, suppliers])

  useEffect(() => {
    let val_1 = 0
    let val_2 = 0
    filteredTableData.forEach((item) => {
      val_1 += item.sparePartCost
      val_2 += item.workCost
    })
    setTotalSparePartsCost(val_1)
    setTotalWorksCost(val_2)
  }, [filteredTableData])

  return (
    <>
      <LinearLoader
        isLoading={!dataReady || !isDataLoaded}
        style={{ height: '4px' }}
      />
      {dataReady && isDataLoaded &&
        <>
          <VirtualizedList
            ref={virtualizedListRefs}
            offsetHeight={140}
            headers={headers}
            pageName={pageName}
            items={filteredTableData}
            filterQuery={filterQuery}
            separateDateKey={pageState.sort.dataType === 'date' && pageState.sort.key}
            navigateTo={pageName}
          />
          <Footer>
            <ListScrollAllSides virtualizedListRefs={virtualizedListRefs} />
            <Button
              onClick={newEntry}
              title={translate('newEntry')}
            >
              {translate('newEntry')}
            </Button>
            {/* ------- FILTER BUTTON ------- */}
            <FilterButton
              filterQuery={filterQuery}
              setFilterQuery={setFilterQuery}
              pageName={pageName}
            />
            {/* ------- TOTAL SPARE PART COST ------- */}
            <ResultInputWrapper>
              <ResultInput
                value={`${totalSparePartsCost?.toLocaleString()} Руб.`}
                forceDarkMode={true}
                borderOption={3}
              />
            </ResultInputWrapper>
            {/* ------- TOTAL WORK COST ------- */}
            <ResultInputWrapper>
              <ResultInput
                value={`${totalWorksCost?.toLocaleString()} Руб.`}
                forceDarkMode={true}
                borderOption={3}
              />
            </ResultInputWrapper>
          </Footer>
        </>
      }
    </>
  )
}