import React, { useCallback, useContext, useEffect, useState } from 'react'
import moment from 'moment'
import ModalContext from '../../../../context/ModalContext'
import Button from '../../../../components/Buttons/Button'
import Library from '../../../../Library'
import styled from 'styled-components'
import useModalContentSize from '../../../../hooks/useModalContentSize'
import TableComponent from '../../../../components/Tables/TableComponent'
import { useTheme } from '../../../../context/ThemeContext'
import ResultInput from '../../../../components/Inputs/ResultInput'
import { Filter } from './Filter'
import useTranslate from '../../../../hooks/useTranslate'
import FilterReset from '../../../../components/Icons/FilterReset'

const Wrapper = styled.div`
  position: relative;
  flex: 1;
`

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
`

const FooterWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 15px;
  justify-content: space-between;
  flex-shrink: 0;
`

export const ShowPayments = (props) => {
  const { employees, employeesSalaries, expenses } = props
  const [filterQuery, setFilterQuery] = useState({ startDate: '', endDate: '', employee: '' })
  const { theme } = useTheme()
  const translate = useTranslate()
  const { closeModal, setCloseOnOutsideClick } = useContext(ModalContext)
  const { isSmallScreen, modalWrapperWidth, modalWrapperHeight, modalContentWidth } = useModalContentSize()

  const [paid, setPaid] = useState('')
  const [remaining, setRemaining] = useState('')
  const [accrued, setAccrued] = useState('')

  const filterQueryHandler = useCallback((newFilterQuery) => {
    setFilterQuery(newFilterQuery)
  }, [setFilterQuery])

  //=== PREPARE TABLE DATA ===//
  const [tableData, setTableData] = useState()
  const [tableDataReady, setTableDataReady] = useState(false)
  const [total, setTotal] = useState(0)

  const [employee, setEmployee] = useState('')
  const [period, setPeriod] = useState('')

  useEffect(() => {
    if (filterQuery) {
      setEmployee(filterQuery.employee)
      setPeriod(filterQuery.startDate ? `${new Date(filterQuery.startDate).getFullYear()}-${(new Date(filterQuery.startDate).getMonth() + 1).toString().length < 2 ? '0' + (new Date(filterQuery.startDate).getMonth() + 1) : new Date(filterQuery.startDate).getMonth() + 1}` : '')
    }
  }, [filterQuery])

  useEffect(() => {
    let data = []
    let totalSum = 0
    function rowData(item) {
      return {
        date: item.date ? item.date : '',
        employee: item.bind ? item.bind : '',
        amount: item.amount ? item.amount : '',
        period: item.period ? Library.replacePeriod(item.period) : '',
        comment: item.comment ? item.comment : '',
      }
    }
    expenses.forEach((item) => {

      if (employee && period) {
        if (item.bindingType === 'Сотрудник' && item.bind === employee && item.period === period) {
          totalSum += item.amount || 0
          data.push(rowData(item))
        }
      } else if (!employee && period) {
        if (item.bindingType === 'Сотрудник' && item.period === period) {
          totalSum += item.amount || 0
          data.push(rowData(item))
        }
      } else if (employee && !period) {
        if (item.bindingType === 'Сотрудник' && item.bind === employee) {
          totalSum += item.amount || 0
          data.push(rowData(item))
        }
      } else {
        if (item.bindingType === 'Сотрудник') {
          totalSum += item.amount || 0
          data.push(rowData(item))
        }
      }
    })
    if (data.length) {
      setTableData(data)
    } else {
      setTableData([{
        date: '',
        employee: '-',
        amount: '-',
        period: '-',
        comment: '-',
      }])
    }
    setTotal(totalSum)
    setTableDataReady(true)
  }, [filterQuery, employee, expenses, period])

  return (
    <Wrapper $isLoading={!tableDataReady} $height={modalWrapperHeight} $width={modalWrapperWidth}>
      <Filter
        filterQuery={filterQuery}
        filterQueryHandler={filterQueryHandler}
        employees={employees}
        employeesSalaries={employeesSalaries}
        expenses={expenses}
        paid={paid}
        setPaid={setPaid}
        remaining={remaining}
        setRemaining={setRemaining}
        accrued={accrued}
        setAccrued={setAccrued}
      />
      <TableComponent columnWidths={['5%', '10%', '30%', '10%', '20%', '25%']} tableWidth={modalContentWidth} isLoading={!tableDataReady}>
        {/* -----HEADERS----- */}
        <thead>
          <tr>
            <th key={'№'}>№</th>
            <th key={'Дата'}>Дата</th>
            <th key={'Сотрудник'}>Сотрудник</th>
            <th key={'Сумма'}>Сумма</th>
            <th key={'Период'}>Период</th>
            <th key={'Комментарий'}>Комментарий</th>
          </tr>
        </thead>
        <tbody>
          {/* -----ROWS----- */}
          {!tableData || tableData.length === 0 ?
            <tr>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
            :
            tableData.map((row, i) => {
              return (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{row.date ? moment(row.date).format('DD.MM.yyyy') : '-'}</td>
                  <td>{Library.findNameByKey(employees, row.employee)}</td>
                  <td><span style={{ color: theme === 'light' ? 'green' : 'var(--green-lighten-color)' }}>{row.amount.toLocaleString()}</span></td>
                  <td>{row.period}</td>
                  <td>{row.comment}</td>
                </tr>
              )
            })}
        </tbody>
      </TableComponent>
      <FooterWrapper $isSmallScreen={isSmallScreen} $width={modalContentWidth}>
        <ButtonsWrapper>
          {/* ------- CLOSE BUTTON ------- */}
          <Button
            onClick={(e) => {
              e.preventDefault()
              setCloseOnOutsideClick(true)
              closeModal()
            }}
          >
            Закрыть
          </Button>
          {/* ------- CLEAR BUTTON ------- */}
          <Button
            style={{ width: '44px', backgroundColor: 'var(--alert-button-bg)', padding: '5px', display: filterQuery.employee || filterQuery.startDate ? 'block' : 'none' }}
            onClick={(e) => {
              e.preventDefault()
              filterQueryHandler({
                startDate: '',
                endDate: '',
                employee: '',
              })
              setPeriod('')
              setPaid('')
              setRemaining('')
              setAccrued('')
            }}
            title={translate('resetFilter')}
          >
            <FilterReset size={22} color={filterQuery.employee || filterQuery.startDate ? '#fff' : 'var(--text-color-disabled)'} />
          </Button>
        </ButtonsWrapper>
        <div style={{ width: '120px' }}>
          <ResultInput
            value={`${total?.toLocaleString() || 0} Руб.`}
            forceDarkMode={true}
            borderOption={3}
          />
        </div>
      </FooterWrapper>
    </Wrapper>
  )
}