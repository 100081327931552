import React, { useCallback, useEffect, useState } from 'react'
import Library from '../../../../Library'
import moment from 'moment'
import DateInput from '../../../../components/Inputs/DateInput'
import Select from '../../../../components/Inputs/Select'
import styled from 'styled-components'
import TextInput from '../../../../components/Inputs/TextInput'
import { useTheme } from '../../../../context/ThemeContext'

const Wrapper = styled.div`
  margin: 15px 0px 0px 5px;
`

const InputsWrapper = styled.div`
  display: grid;
  gap: 1rem;
  margin: 1rem 0 0.5rem 0;
  height: auto;
  min-height: 85px;
  width: 100%;
  align-items: center;

  @media (min-width: 1150px) {
    display: flex;
  }
`

const EmployeeInputWrapper = styled.div`
  width: 100%;
  @media (min-width: 1150px) {
    min-width: 250px;
    max-width: 250px;
  }
`

const InnerInputWrapper = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: 1150px) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  }
`

const PeriodInputWrapper = styled.div`
  max-width: 150px;
`

const ResultInputWrapper = styled.div`
  max-width: 150px;
`

export const Filter = ({ employeesSalaries, expenses, employees, filterQuery, filterQueryHandler, paid, remaining, accrued, setPaid, setRemaining, setAccrued }) => {
  const { theme } = useTheme()
  const [period, setPeriod] = useState('')
  const [dataReady, setDataReady] = useState(false)

  const getSelectOptions = useCallback((data) => {
    let arr = [
      {
        value: '',
        label: '-',
        defaultValue: true,
        disabled: false,
      },
    ]
    if (data.length) {
      data.forEach((item) => {
        arr.push({ value: item.id, label: item.name })
      })
    }
    return arr
  }, [])

  const getEmployeeSelectOptions = useCallback(() => {
    let arr = [
      {
        value: '',
        label: '-',
        defaultValue: true,
        disabled: false,
      },
    ]
    if (employeesSalaries.length) {
      const startDate = filterQuery.startDate
      const endDate = filterQuery.endDate
      let tmp = []
      employeesSalaries.forEach((item) => {
        if (
          moment(item?.dateOfDeparture).isBetween(
            startDate,
            endDate,
            'days',
            '[]'
          ) &&
          item?.employee &&
          !tmp.includes(item.employee)
        ) {
          tmp.push(item.employee)
        }
      })
      if (tmp.length) {
        tmp.forEach((item) => {
          let label = Library.findNameByKey(employees, item)
          arr.push({
            value: item,
            label: label ? label : '',
          })
        })
      }
      arr.sort((a, b) => a.label.localeCompare(b.label))
    }
    return arr
  }, [employeesSalaries, employees, filterQuery.startDate, filterQuery.endDate])

  const [employeeOptions, setEmployeeOptions] = useState([])
  useEffect(() => {
    if (period) {
      setEmployeeOptions(getEmployeeSelectOptions())
    } else {
      setEmployeeOptions(getSelectOptions(employees))
    }
  }, [employeesSalaries, employees, filterQuery.startDate, getEmployeeSelectOptions, getSelectOptions, period])

  useEffect(() => {
    let newPeriod = ''
    if (filterQuery.startDate) {
      const year = `${new Date(filterQuery.startDate).getFullYear()}`
      const month = `${new Date(filterQuery.startDate).getMonth() + 1}`
      newPeriod = `${year}-${month.length < 2 ? '0' + month : month}`
      setPeriod(newPeriod)
    } else {
      setPeriod('')
    }

    let calculatedPaid = 0
    let calculatedAccrued = 0

    // Calculate accrued with period and employee filters
    employeesSalaries.forEach((item) => {
      const itemDate = new Date(item.date) // adjust if necessary
      const isEmployeeMatch = !filterQuery.employee || item.employee === filterQuery.employee
      const isPeriodMatch = !filterQuery.startDate ||
        (moment(itemDate).isBetween(filterQuery.startDate, filterQuery.endDate, 'days', '[]'))

      if (isEmployeeMatch && isPeriodMatch) {
        calculatedAccrued += parseFloat(item.amount || 0)
      }
    })

    // Calculate paid (already considering period and employee)
    expenses.forEach((expense) => {
      const periodFilter = expense.period === newPeriod
      const employeeFilter = expense.bind === filterQuery.employee
      const isEmployee = expense.bindingType === 'Сотрудник'

      if (newPeriod && filterQuery.employee) {
        if (periodFilter && isEmployee && employeeFilter) {
          calculatedPaid += parseFloat(expense.amount || 0)
        }
      } else if (newPeriod && !filterQuery.employee) {
        if (periodFilter && isEmployee) {
          calculatedPaid += parseFloat(expense.amount || 0)
        }
      } else if (!newPeriod && filterQuery.employee) {
        if (isEmployee && employeeFilter) {
          calculatedPaid += parseFloat(expense.amount || 0)
        }
      } else {
        if (isEmployee) {
          calculatedPaid += parseFloat(expense.amount || 0)
        }
      }
    })

    // Calculate remaining
    const calculatedRemaining = calculatedAccrued - calculatedPaid

    setPaid(calculatedPaid)
    setRemaining(calculatedRemaining)
    setAccrued(calculatedAccrued)

    setDataReady(true)
  }, [filterQuery, employeesSalaries, expenses, setAccrued, setPaid, setRemaining])

  return (
    <Wrapper>
      {dataReady &&
        <InputsWrapper>
          {/* ------- EMPLOYEE ------- */}
          <EmployeeInputWrapper>
            < Select
              label='Сорудник'
              id='employee'
              name='employee'
              value={filterQuery.employee}
              options={employeeOptions}
              searchable={true}
              onChange={(e) => {
                filterQueryHandler({
                  ...filterQuery,
                  employee: e.target.value
                })
              }}
            />
          </EmployeeInputWrapper>
          <InnerInputWrapper>
            {/* ------- PERIOD ------- */}
            <PeriodInputWrapper>
              <DateInput
                label='Период'
                name='period'
                id='period'
                type='month'
                value={period}
                onChange={(e) => {
                  const str = e.target.value
                  const startDate = str ? new Date(str.slice(0, 4), parseFloat(str.slice(-2)) - 1, 1) : ''
                  const endDate = str ? new Date(str.slice(0, 4), parseFloat(str.slice(-2)), 0) : ''
                  filterQueryHandler({
                    ...filterQuery,
                    startDate, endDate
                  })
                }}
              />
            </PeriodInputWrapper>
            {/* ------- ACCRUED ------- */}
            <ResultInputWrapper>
              <TextInput
                style={{ color: theme === 'light' ? 'red' : 'var(--red-lighten-color)' }}
                label='Начислено'
                name='accrued'
                id='accrued'
                value={accrued ? accrued.toLocaleString() + ' р.' : '0'}
                readOnly={true}
              />
            </ResultInputWrapper>
            {/* ------- PAID ------- */}
            <ResultInputWrapper>
              <TextInput
                style={{ color: theme === 'light' ? 'green' : 'var(--green-lighten-color)' }}
                label='Выплачено'
                name='paid'
                id='paid'
                value={paid ? paid.toLocaleString() + ' р.' : '0'}
                readOnly={true}
              />
            </ResultInputWrapper>
            {/* ------- REMAINING ------- */}
            <ResultInputWrapper>
              <TextInput
                style={{ color: theme === 'light' ? 'blue' : 'var(--blue-lighten-color)' }}
                label='Остаток'
                name='remaining'
                id='remaining'
                value={remaining ? remaining.toLocaleString() + ' р.' : '0'}
                readOnly={true}
              />
            </ResultInputWrapper>
          </InnerInputWrapper>
        </InputsWrapper>
      }
    </Wrapper >
  )
}