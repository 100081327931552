import React, { useContext, useEffect, useState } from 'react'
import ModalContext from '../../../../context/ModalContext'
import styled, { css } from 'styled-components'
import useModalContentSize from '../../../../hooks/useModalContentSize'
import TableComponent from '../../../../components/Tables/TableComponent'
import moment from 'moment'
import Button from '../../../../components/Buttons/Button'

const Wrapper = styled.div.attrs(props => ({
  style: {
    width: props.$width + 'px',
    maxHeight: props.$height + 'px',
  },
}))`
${(props) =>
    props.$isLoading &&
    css`
  height: ${props => props.$height + 'px'};
`}
  position: relative;
  display: flex;
  flex-direction: column;
`

const TitleWrapper = styled.div`
  font-size: 1em;
  color: var(--text-color);
  font-weight: bold;
  margin-bottom: 0.5rem;
`

const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin: 15px 10px 15px 0;
  font-size: 0.8em;
  color: var(--text-color);
  @media (min-width: 768px) {
    font-size: 1em;
  }
`

const SummaryRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
`

const LabelContainer = styled.div`
  display: flex;
`

const AmountContainer = styled.div`
  display: flex;
`

const SummaryLabel = styled.span`
  font-weight: bold;
  color: var(--text-color);
`

const SummaryValue = styled.span`
  color: ${(props) => (props.isOverdue ? 'red' : props.noOverdue ? 'green' : 'var(--text-color)')};
  text-align: left;
  padding-left: 8px;
  flex-grow: 1;
`

const FooterWrapper = styled.div.attrs(props => ({
  style: {
    width: props.$width + 'px',
  },
}))`
  display: flex;
  margin: 15px 0px 0px 0px;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
`

export const Debt = (props) => {
  const { data } = props
  const { closeModal } = useContext(ModalContext)
  const { isSmallScreen, modalWrapperWidth, modalWrapperHeight, modalContentWidth } = useModalContentSize()

  //=== PREPARE TABLE DATA ===//
  const [tableData, setTableData] = useState()
  const [tableDataReady, setTableDataReady] = useState(false)
  const [total, setTotal] = useState(0)
  const [overdueTotal, setOverdueTotal] = useState(0)
  const [paymentTypeData, setPaymentTypeData] = useState([])

  useEffect(() => {
    let totalSum = 0
    let overdueSum = 0
    let obj = {}
    let paymentTypeTotals = {
      'Ндс': { amount: 0, overdue: 0 },
      'Без ндс': { amount: 0, overdue: 0 },
      'Нал': { amount: 0, overdue: 0 },
      "": { amount: 0, overdue: 0 }
    }

    const today = moment().format('YYYY-MM-DD') // Get today's date formatted

    data.forEach((item) => {
      if (item.paymentStatus !== 'Оплачен') {
        totalSum += item.routeCost
        const dueDate = item.dueDate ? moment(item.dueDate).format('YYYY-MM-DD') : null

        // Calculate overdue amount
        if (dueDate && moment(dueDate).isBefore(today)) {
          overdueSum += item.routeCost
          paymentTypeTotals[item.paymentType].overdue += item.routeCost
        }

        paymentTypeTotals[item.paymentType].amount += item.routeCost

        if (Object.keys(obj).includes(item.customer)) {
          obj[item.customer].amount += item.routeCost
          obj[item.customer].count += 1

          // Update overdue amount for the customer
          if (dueDate && moment(dueDate).isBefore(today)) {
            obj[item.customer].overdueAmount = (obj[item.customer].overdueAmount || 0) + item.routeCost
          } else if (!obj[item.customer].overdueAmount) {
            obj[item.customer].overdueAmount = 0
          }
        } else {
          obj[item.customer] = {
            amount: item.routeCost,
            count: 1,
            overdueAmount: dueDate && moment(dueDate).isBefore(today) ? item.routeCost : 0
          }
        }
      }
    })

    const tableDataArray = Object.keys(obj).map(customer => ({ customer, ...obj[customer] }))

    tableDataArray.sort((a, b) => {
      const customerA = a.customer.toUpperCase()
      const customerB = b.customer.toUpperCase()

      if (customerA < customerB) {
        return -1
      }
      if (customerA > customerB) {
        return 1
      }
      return 0
    })

    setTotal(totalSum)
    setOverdueTotal(overdueSum)
    setTableData(tableDataArray)
    setPaymentTypeData(Object.entries(paymentTypeTotals).map(([type, { amount, overdue }]) => ({
      paymentType: type || "Не указан",
      amount,
      overdue,
      percent: totalSum ? ((overdue / totalSum) * 100).toFixed(2) : '0.00'
    })))
    setTableDataReady(true)
  }, [data])

  return (
    <Wrapper $isLoading={!tableDataReady} $height={modalWrapperHeight} $width={modalWrapperWidth}>
      <TitleWrapper>Дебиторка:</TitleWrapper>
      <TableComponent columnWidths={['40%', '20%', '20%', '20%']} tableWidth={modalContentWidth} isLoading={!tableDataReady}>
        {/* -----HEADERS----- */}
        <thead>
          <tr>
            <th key='name'>Заказчик</th>
            <th key='amount'>Сумма долга</th>
            <th key='overdue'>Просрочено</th>
            <th key='count'>Кол-во</th>
          </tr>
        </thead>
        <tbody>
          {/* -----ROWS----- */}
          {tableData && tableData
            .filter(item => item.amount)
            .map((item) => {
              const { customer, amount, overdueAmount, count } = item
              return (
                <tr key={customer}>
                  <td>{['', 'null', null, undefined].includes(customer) ? '-' : customer}</td>
                  <td>{amount ? `${parseFloat(amount.toFixed()).toLocaleString()} р.` : '-'}</td>
                  <td>{overdueAmount ? <span style={{ color: 'red' }}>{`${parseFloat(overdueAmount.toFixed()).toLocaleString()} р.`}</span> : '-'}</td>
                  <td>{count ? count.toLocaleString() : '-'}</td>
                </tr>
              )
            })}
        </tbody>
      </TableComponent>
      {/* Payment Type Summary Info */}
      <SummaryWrapper>
        {paymentTypeData.map(({ paymentType, amount, overdue, percent }) => (
          <SummaryRow key={paymentType}>
            <AmountContainer>
              <SummaryLabel>{paymentType}:</SummaryLabel>
            </AmountContainer>
            <LabelContainer>
              <SummaryValue>{amount.toLocaleString()} р.</SummaryValue>
            </LabelContainer>
            <SummaryValue
              isOverdue={overdue > 0}
              noOverdue={overdue === 0} // Pass noOverdue prop if overdue is zero
            >
              {overdue > 0
                ? `Просрочено: ${overdue.toLocaleString()} р. (${percent}%)`
                : 'Нет просроченной задолженности'}
            </SummaryValue>
          </SummaryRow>
        ))}
        <SummaryRow>
          <LabelContainer>
            <SummaryLabel>Итого:</SummaryLabel>
          </LabelContainer>
          <AmountContainer>
            <SummaryValue>{total?.toLocaleString()} р.</SummaryValue>
          </AmountContainer>
          <SummaryValue
            isOverdue={overdueTotal > 0}
            noOverdue={overdueTotal === 0} // Pass noOverdue prop if total overdue is zero
          >
            {overdueTotal > 0
              ? `Просрочено: ${overdueTotal.toLocaleString()} р. (${((overdueTotal / total) * 100).toFixed(2)}%)`
              : 'Нет просроченной задолженности'}
          </SummaryValue>
        </SummaryRow>
      </SummaryWrapper>
      <FooterWrapper $isSmallScreen={isSmallScreen} $width={modalContentWidth}>
        <Button
          style={{ minWidth: '100px' }}
          onClick={(e) => {
            e.preventDefault()
            closeModal()
          }}
        >
          Закрыть
        </Button>
      </FooterWrapper>
    </Wrapper>
  )
}