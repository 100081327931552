import { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'
import { logoutUser } from '../store/actions/authActions'
import { useSocketContext } from '../context/SocketContext'
import { useSoundTheme } from '../hooks/useSoundTheme'
import ModalContext from '../context/ModalContext'
import UserMessageModal from './AdminBroadcast/UserMessageModal'

const SocketListener = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isDataLoaded = useSelector((state) => state.loading.isDataLoaded)
  const { companyId } = useSelector(state => state.data)
  const { notificationSound } = useSoundTheme()
  const { showModal, setModalContent, setModalPadding } = useContext(ModalContext)

  const { id: userId } = useAuth()

  const socket = useSocketContext()

  useEffect(() => {

    if (!isDataLoaded) return

    //------------(BROADCAST MESSAGE)------------//
    const handleBroadcastMessage = (item) => {
      notificationSound()
      setModalContent(<UserMessageModal item={item} />)
      setModalPadding({ smallScreen: '30px 5px 20px 5px;', largeScreen: '25px' })
      showModal()
    }

    //------------(UPDATE ONLINE USERS)------------//
    const handleOnlineUsers = (onlineUsersData) => {
      dispatch({
        type: 'UPDATE_ONLINE_USERS',
        payload: onlineUsersData,
      })
    }

    //------------(TOGGLE ACCESS)------------//
    const handleToggleAccess = (item) => {
      if (item.id === userId && !item.accessEnabled) {
        dispatch(logoutUser(navigate, socket))
      } else {
        dispatch({
          type: 'TOGGLE_ACCESS',
          payload: item,
        })
      }
    }

    //------------(USERS)------------//
    const handleUserUpdate = (item) => {
      dispatch({
        type: 'UPDATE_USER',
        payload: item.updatedData,
      })
    }

    const handleUserAdd = (item) => {
      dispatch({
        type: 'ADD_USER',
        payload: item.newData,
      })
    }

    const handleUserDelete = (item) => {
      dispatch({
        type: 'DELETE_USER',
        payload: item.deletedData,
      })
    }

    //------------(DRIVERS)------------//
    const handleDriverUpdate = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'UPDATE_DRIVER',
        payload: item.data
      })
    }

    const handleDriverAdd = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'ADD_DRIVER',
        payload: item.data
      })
    }

    const handleDriverDelete = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'DELETE_DRIVER',
        payload: item.data
      })
    }

    //------------(CARS)------------//
    const handleCarUpdate = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'UPDATE_CAR',
        payload: item.data
      })
    }

    const handleCarAdd = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'ADD_CAR',
        payload: item.data
      })
    }

    const handleCarDelete = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'DELETE_CAR',
        payload: item.data
      })
    }

    //------------(HIRED CARS)------------//
    const handleHiredCarUpdate = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'UPDATE_HIRED_CAR',
        payload: item.data
      })
    }

    const handleHiredCarAdd = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'ADD_HIRED_CAR',
        payload: item.data
      })
    }

    const handleHiredCarDelete = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'DELETE_HIRED_CAR',
        payload: item.data
      })
    }

     //------------(HIRED DRIVERS)------------//
     const handleHiredDriverUpdate = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'UPDATE_HIRED_DRIVER',
        payload: item.data
      })
    }

    const handleHiredDriverAdd = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'ADD_HIRED_DRIVER',
        payload: item.data
      })
    }

    const handleHiredDriverDelete = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'DELETE_HIRED_DRIVER',
        payload: item.data
      })
    }

    //------------(ROUTES)------------//
    const handleRouteUpdate = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'UPDATE_ROUTE',
        payload: item.data
      })
    }

    const handleRouteAdd = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'ADD_ROUTE',
        payload: item.data
      })
    }

    const handleRouteDelete = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'DELETE_ROUTE',
        payload: item.data
      })
    }

    //------------(EMPLOYEES)------------//
    const handleEmployeeUpdate = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'UPDATE_EMPLOYEE',
        payload: item.data
      })
    }

    const handleEmployeeAdd = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'ADD_EMPLOYEE',
        payload: item.data
      })
    }

    const handleEmployeeDelete = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'DELETE_EMPLOYEE',
        payload: item.data
      })
    }

    //------------(EMPLOYEES SALARIES)------------//
    const handleEmployeeSalariesUpdate = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'UPDATE_EMPLOYEES_SALARIES',
        payload: item.data
      })
      console.log(item.data)
    }
    
    const handleEmployeeSalariesAdd = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'ADD_EMPLOYEES_SALARIES',
        payload: item.data
      })
      console.log(item.data)
    }
    
    const handleEmployeeSalariesDelete = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'DELETE_EMPLOYEES_SALARIES',
        payload: item.data
      })
      console.log(item.data)
    }

    //------------(CUSTOMER)------------//

    const handleCustomerUpdate = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'UPDATE_CUSTOMER',
        payload: item.data
      })
    }

    const handleCustomerAdd = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'ADD_CUSTOMER',
        payload: item.data
      })
    }

    const handleCustomerDelete = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'DELETE_CUSTOMER',
        payload: item.data
      })
    }

    //------------(CARRIER)------------//

    const handleCarrierUpdate = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'UPDATE_CARRIER',
        payload: item.data
      })
    }

    const handleCarrierAdd = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'ADD_CARRIER',
        payload: item.data
      })
    }

    const handleCarrierDelete = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'DELETE_CARRIER',
        payload: item.data
      })
    }

    //------------(SUPPLIER)------------//
    const handleSupplierUpdate = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'UPDATE_SUPPLIER',
        payload: item.data
      })
    }

    const handleSupplierAdd = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'ADD_SUPPLIER',
        payload: item.data
      })
    }

    const handleSupplierDelete = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'DELETE_SUPPLIER',
        payload: item.data
      })
    }

    //------------(EXPENSE)------------//
    const handleExpenseUpdate = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'UPDATE_EXPENSE',
        payload: item.data
      })
    }

    const handleExpenseAdd = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'ADD_EXPENSE',
        payload: item.data
      })
    }

    const handleExpenseDelete = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'DELETE_EXPENSE',
        payload: item.data
      })
    }

    //------------(REFUELING)------------//
    const handleRefuelingUpdate = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'UPDATE_REFUELING',
        payload: item.data
      })
    }

    const handleRefuelingAdd = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'ADD_REFUELING',
        payload: item.data
      })
    }

    const handleRefuelingDelete = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'DELETE_REFUELING',
        payload: item.data
      })
    }

    //------------(MAINTENANCE)------------//
    const handleMaintenanceUpdate = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'UPDATE_MAINTENANCE',
        payload: item.data
      })
    }

    const handleMaintenanceAdd = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'ADD_MAINTENANCE',
        payload: item.data
      })
    }

    const handleMaintenanceDelete = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'DELETE_MAINTENANCE',
        payload: item.data
      })
    }

    //------------(INVENTORY)------------//
    const handleInventoryUpdate = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'UPDATE_INVENTORY',
        payload: item.data
      })
    }

    const handleInventoryAdd = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'ADD_INVENTORY',
        payload: item.data
      })
    }

    const handleInventoryDelete = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'DELETE_INVENTORY',
        payload: item.data
      })
    }

    //------------(STOCK)------------//
    const handleStockUpdate = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'UPDATE_STOCK',
        payload: item.data
      })
    }

    const handleStockAdd = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'ADD_STOCK',
        payload: item.data
      })
    }

    const handleStockDelete = (item) => {
      if (item.companyId !== companyId) return
      dispatch({
        type: 'DELETE_STOCK',
        payload: item.data
      })
    }

    const eventHandlers = [
      //------------(BROADCAST MESSAGE)------------//
      { event: 'broadcast_message', handler: handleBroadcastMessage },
      //------------(ONLINE USERS)------------//
      { event: 'online_users', handler: handleOnlineUsers },
      //------------(TOGGLE ACCESS)------------//
      { event: 'toggle_access', handler: handleToggleAccess },
      //------------(USERS)------------//
      { event: 'update_user', handler: handleUserUpdate },
      { event: 'add_user', handler: handleUserAdd },
      { event: 'delete_user', handler: handleUserDelete },
      //------------(DRIVERS)------------//
      { event: 'update_driver', handler: handleDriverUpdate },
      { event: 'add_driver', handler: handleDriverAdd },
      { event: 'delete_driver', handler: handleDriverDelete },
      //------------(CARS)------------//
      { event: 'update_car', handler: handleCarUpdate },
      { event: 'add_car', handler: handleCarAdd },
      { event: 'delete_car', handler: handleCarDelete },
      //------------(HIRED CARS)------------//
      { event: 'update_hired_car', handler: handleHiredCarUpdate },
      { event: 'add_hired_car', handler: handleHiredCarAdd },
      { event: 'delete_hired_car', handler: handleHiredCarDelete },
      //------------(HIRED DRIVERS)------------//
      { event: 'update_hired_driver', handler: handleHiredDriverUpdate },
      { event: 'add_hired_driver', handler: handleHiredDriverAdd },
      { event: 'delete_hired_driver', handler: handleHiredDriverDelete },
      //------------(ROUTES)------------//
      { event: 'update_route', handler: handleRouteUpdate },
      { event: 'add_route', handler: handleRouteAdd },
      { event: 'delete_route', handler: handleRouteDelete },
      //------------(EMPLOYEES)------------//
      { event: 'update_employee', handler: handleEmployeeUpdate },
      { event: 'add_employee', handler: handleEmployeeAdd },
      { event: 'delete_employee', handler: handleEmployeeDelete },
      //------------(EMPLOYEES SALARIES)------------//
      { event: 'update_employees_salaries', handler: handleEmployeeSalariesUpdate },
      { event: 'add_employees_salaries', handler: handleEmployeeSalariesAdd },
      { event: 'delete_employees_salaries', handler: handleEmployeeSalariesDelete },
      //------------(CUSTOMERS)------------//
      { event: 'update_customer', handler: handleCustomerUpdate },
      { event: 'add_customer', handler: handleCustomerAdd },
      { event: 'delete_customer', handler: handleCustomerDelete },
      //------------(CARRIERS)------------//
      { event: 'update_carrier', handler: handleCarrierUpdate },
      { event: 'add_carrier', handler: handleCarrierAdd },
      { event: 'delete_carrier', handler: handleCarrierDelete },
      //------------(SUPPLIERS)------------//
      { event: 'update_supplier', handler: handleSupplierUpdate },
      { event: 'add_supplier', handler: handleSupplierAdd },
      { event: 'delete_supplier', handler: handleSupplierDelete },
      //------------(EXPENSES)------------//
      { event: 'update_expense', handler: handleExpenseUpdate },
      { event: 'add_expense', handler: handleExpenseAdd },
      { event: 'delete_expense', handler: handleExpenseDelete },
      //------------(REFUELING)------------//
      { event: 'update_refueling', handler: handleRefuelingUpdate },
      { event: 'add_refueling', handler: handleRefuelingAdd },
      { event: 'delete_refueling', handler: handleRefuelingDelete },
      //------------(MAINTENANCE)------------//
      { event: 'update_maintenance', handler: handleMaintenanceUpdate },
      { event: 'add_maintenance', handler: handleMaintenanceAdd },
      { event: 'delete_maintenance', handler: handleMaintenanceDelete },
      //------------(INVENTORY)------------//
      { event: 'update_inventory', handler: handleInventoryUpdate },
      { event: 'add_inventory', handler: handleInventoryAdd },
      { event: 'delete_inventory', handler: handleInventoryDelete },
      //------------(STOCK)------------//
      { event: 'update_stock', handler: handleStockUpdate },
      { event: 'add_stock', handler: handleStockAdd },
      { event: 'delete_stock', handler: handleStockDelete },
    ]

    // Function to set up all event listeners
    function setupEventListeners(socket) {
      eventHandlers.forEach(({ event, handler }) => {
        socket.on(event, handler)
      })
    }

    // Function to tear down all event listeners
    function tearDownEventListeners(socket) {
      eventHandlers.forEach(({ event, handler }) => {
        socket.off(event, handler)
      })
    }

    setupEventListeners(socket)

    //------------(CLEAR)------------//
    return () => {
      tearDownEventListeners(socket)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, socket, isDataLoaded, userId])

  return null
}

export default SocketListener
