import React, { createContext, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import styled from 'styled-components'
import { FixedSizeList as List } from 'react-window'
import Library from '../../../../Library'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useSingleAndDoubleClick from '../../../../hooks/useSingleAndDoubleClick'
import { useTheme } from '../../../../context/ThemeContext'
import moment from 'moment'
import ContextMenu from '../../../../components/ContextMenu/ContextMenu'
import ListCross from './icons/ListCross'
import ListCheck from './icons/ListCheck'

const ListWrapper = styled.div`
  font-family: sans-serif;
  font-size: 0.8em;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  width: 100%;
`

const Headers = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  padding: 0;
  font-weight: bold;
  border-radius: 0px;
  text-align: center;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  flex-shrink: 0;
  gap: 5px;
  width: ${(props) => props.$width}px;
  background-color: var(--surface-color);
  padding-right: ${props => props.$isSortColumn ? 10 : 0}px;
`

const ActiveRowAfter = styled.div`
  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    width: ${({ width }) => width}px;
    height: calc(100% + 1px);
    outline: 3px groove var(--list-outline-color);
    outline-offset: -2px;
    pointer-events: none;
  }
`

const Cell = styled.div`
  display: flex;
  align-items: center;
  color: var(--list-text-color);
  html[data-theme='dark'] & {
    color: #fff;
    }
  border-radius: 0px;
  border-left: 1px solid var(--surface-color);
  border-top: 1px solid var(--surface-color);
  flex-shrink: 0;
  width: ${(props) => props.$width}px;

  ${({ $isLastCol }) => $isLastCol && `
    border-right: 1px solid var(--surface-color);
  `}

  ${({ $isLastRow }) => $isLastRow && `
    && {
      border-bottom: 2px solid var(--list-line-color-variant);
    }
  `}
`

const CellValueWrapper = styled.div`
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;
`

//------- FORMAT CELL VALUE -------//
const formatCellValue = (item, header, index, filterQuery, isActive, isHovered) => {
  const key = header.id
  const type = header.type
  switch (type) {
    case 'timestamp': {
      const value = Library.formatDate(item[key], 'DD.MM.yyyy HH:mm:ss')
      return [Library.highlight(value, filterQuery.search), value]
    }
    case 'status': {
      switch (item[key]) {
        case 'markedForDeletion': {
          return [<div style={{ height: '30px' }}><ListCross size={28} isActive={isActive} isHovered={isHovered} /></div>, 'Помечен на удаление']
        }
        default: {
          return [<div style={{ height: '30px' }}><ListCheck size={28} isActive={isActive} isHovered={isHovered} /></div>, 'Активный']
        }
      }
    }
    case 'date': {
      const value = Library.formatDate(item[key], 'DD.MM.yyyy')
      return [Library.highlight(value, filterQuery.search), value]
    }
    case 'check-mark': {
      const value = item[key] ? '☑' : ''
      return [Library.highlight(value, filterQuery.search), value]
    }
    case 'increment': {
      const value = `${index + 1}`
      return [Library.highlight(value, filterQuery.search), value]
    }
    case 'url': {
      const url = Library.checkUrl(item[key])
      return [item[key] ?
        <a href={url} target='_blank' rel='noopener noreferrer'>{Library.highlight(url, filterQuery.search)}</a> :
        '', url]
    }
    default: {
      const value =
        typeof item[key] === 'number'
          ? parseFloat(item[key]).toLocaleString()
          : item[key]
      return [Library.highlight(value, filterQuery.search), value]
    }
  }
}

function getCellStyles(item, previousDateNotEqual, isLastCol, theme) {
  let styles = {}
  if (previousDateNotEqual) {
    styles['borderTop'] = `2px solid ${theme === 'light' ? '#373737' : 'var(--surface-color)'}`
  }
  if (isLastCol) return styles

  // Directly handle the 'Завис' case
  if (item.paymentStatus === 'Завис') {
    styles['backgroundColor'] = 'var(--list-orange-bg)'
  } else if (item.paymentType !== 'Нал') {
    switch (item.paymentStatus) {
      case 'Оплачен': {
        styles['backgroundColor'] = 'var(--list-green-bg)'
        break
      }
      default: {
        styles['backgroundColor'] = 'var(--list-red-bg)'
      }
    }
  } else {
    if (item.paymentStatus !== 'Оплачен') {
      styles['backgroundColor'] = 'var(--list-yellow-bg)'
    } else {
      styles['backgroundColor'] = 'var(--list-green-bg)'
    }
  }

  return styles
}

const StickyListContext = createContext()
StickyListContext.displayName = 'StickyListContext'

const ItemWrapper = ({ data, index, style }) => {
  const { ItemRenderer } = data
  return <ItemRenderer index={index} style={style} />
}

const Row = ({ headers, items, index, style, width, isActive, onClick, filterQuery, theme, separateDateKey, onRightClick, contextMenu }) => {
  const item = items[index]
  const previousDateNotEqual = separateDateKey && index > 0 && moment(items[index - 1][separateDateKey]).format('YYYY-MM-DD') !== moment(item[separateDateKey]).format('YYYY-MM-DD')
  const isLastRow = index === items.length - 1
  const top = style.top + 60

  const handleRightClickContextMenu = (e) => {
    if (!contextMenu) return
    const selection = window.getSelection()
    if (selection.toString().length > 1) return
    e.preventDefault()

    const rowRect = e.currentTarget.getBoundingClientRect()
    const x = e.pageX
    const y = rowRect.bottom + 75

    onRightClick(index, { x, y }, item)
  }

  const handleTwoFingerTouchContextMenu = (e) => {
    if (!contextMenu) return
    if (e.touches.length === 2) {
      e.preventDefault()

      const rowRect = e.currentTarget.getBoundingClientRect()
      const touchMidX = (e.touches[0].pageX + e.touches[1].pageX) / 2

      const x = touchMidX
      const y = rowRect.bottom + 75

      onRightClick(index, { x, y }, item)
    }
  }

  const visibleHeaders = headers.filter((header) => !header.hidden)
  const totalWidth = visibleHeaders.reduce(
    (acc, header) => acc + header.width,
    0
  )

  const lastColumnId = visibleHeaders[visibleHeaders.length - 1].id

  return (
    <div
      id={item.id}
      style={{ ...style, top, display: 'flex', width }}
      className={isActive ? 'active-row' : ''}
      onClick={onClick}
      onContextMenu={handleRightClickContextMenu}
      onTouchStart={handleTwoFingerTouchContextMenu}
    >
      {isActive && <ActiveRowAfter width={totalWidth} />}
      {
        headers.map((header) => {
          const isLastCol = header.id === lastColumnId
          const [cellValue, cellTitle] = formatCellValue(item, header, index, filterQuery)

          let cellStyles = getCellStyles(item, previousDateNotEqual, isLastCol, theme)

          if (isLastCol) {
            // calculate the difference in days between today and the timestamp
            const diffDays = moment().diff(moment(item[header.id]), 'days')

            // apply conditional formatting
            if (diffDays > 30 && diffDays <= 1000) {
              cellStyles = { ...cellStyles, color: theme === 'light' ? '#ff0000' : 'var(--red-lighten-color)' } // red
            } else if (diffDays > 14 && diffDays <= 30) {
              cellStyles = { ...cellStyles, color: theme === 'light' ? '#fca228' : 'var(--orange-lighten-color)' } // orange
            } else if (diffDays >= 0 && diffDays <= 14) {
              cellStyles = { ...cellStyles, color: theme === 'light' ? '#009926' : 'var(--green-lighten-color)' } // green
            }
          }

          return !header.hidden && (
            <Cell
              key={header.id}
              style={{
                ...cellStyles,
                width: header.width,
                flexShrink: 0,
                fontWeight: header.id === 'dateOfDeparture' || header.id === 'lastModified' ? 'bold' : 'normal',
                borderRight: isLastCol ? '1px solid var(--line-color)' : undefined,
              }}
              title={cellTitle}
              $theme={theme}
              $isLastCol={isLastCol}
              $isLastRow={isLastRow}
            >
              <CellValueWrapper>
                {cellValue}
              </CellValueWrapper>
            </Cell>
          )
        })
      }
    </div>
  )
}

const HeadersRow = forwardRef(({ headers, style, setActiveIndex, setActiveRowId, setSort, sort }, ref) => {
  const onHeaderClick = () => {
    setActiveIndex(-1)
    setActiveRowId('')
  }
  return (
    <Headers
      ref={ref}
      onClick={onHeaderClick}
      style={{
        ...style,
        position: 'sticky',
        top: 0,
        zIndex: 10,
        height: '60px',
      }}
    >
      {headers.map(
        (header) =>
          !header.hidden && (
            <Header
              key={header.id}
              title={header.name}
              onClick={() => {
                if (header.type === 'increment') return
                setSort(header)
              }}
              $isSortColumn={sort.key === header.id}
              $width={header.width}
            >
              <span
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '100%',
                }}
              >
                {header.name}
              </span>
              {sort.key === header.id && (
                <span>
                  {sort.direction ? '↓' : '↑'}
                </span>
              )}
            </Header>
          )
      )}
    </Headers>
  )
})

const innerElementType = forwardRef(({ children, ...rest }, ref) => (
  <StickyListContext.Consumer>
    {({ headers, headerRef, setActiveIndex, setActiveRowId, setSort, sort }) => (
      <div ref={ref} {...rest}>
        <HeadersRow
          ref={headerRef}
          headers={headers}
          setActiveIndex={setActiveIndex}
          setActiveRowId={setActiveRowId}
          setSort={setSort}
          sort={sort}
          key={'headerRow'}
          id='headerRow'
        />
        {children}
      </div>
    )}
  </StickyListContext.Consumer>
))

const StickyList = forwardRef(({ children, headers, headerRef, setActiveIndex, setActiveRowId, setSort, sort, ...rest }, ref) => (
  <StickyListContext.Provider
    value={{ ItemRenderer: children, headers, headerRef, setActiveIndex, setActiveRowId, setSort, sort }}
  >
    <List
      ref={ref}
      itemData={{ ItemRenderer: children }}
      {...rest}
    >
      {ItemWrapper}
    </List>
  </StickyListContext.Provider>
))

const VirtualizedList = forwardRef((props, refs) => {
  // Ref to track the initial render
  const isInitialRender = useRef(true)
  const { headersHeight = 60, pageName, path = 'data', pageState, items, activeIndex, setActiveIndex, filterQuery, navigateTo, separateDateKey, contextMenu = true, labelConfig = { labelKey: 'dateOfDeparture', labelFormat: 'date' } } = props
  const dispatch = useDispatch()
  const router = useNavigate()
  const { theme } = useTheme()
  const lineHeight = 30
  const listWrapperRef = useRef(null)
  const [listRefCurrent, setListRefCurrent] = useState(null)
  const headers = useMemo(() => [
    { id: 'id', name: 'id', width: 275, hidden: true },
    { id: 'timestamp', name: 'timestamp', dataType: 'date', type: 'timestamp', width: 165, hidden: true },
    { id: 'number', name: '№', dataType: 'number', type: 'increment', width: 50 },
    { id: 'status', name: 'Статус', type: 'status', width: 60 },
    { id: 'documentsSent', name: 'Док. отправлены', dataType: 'date', type: 'date', width: 85 },
    { id: 'documentsReceived', name: 'Док. получены', dataType: 'date', type: 'date', width: 85 },
    { id: 'dueDate', name: 'Срок оплаты', dataType: 'date', type: 'date', width: 85 },
    { id: 'paymentStatus', name: 'Статус оплаты', width: 70 },
    { id: 'contractNumber', name: 'Д-з №', width: 100 },
    { id: 'cargoName', name: 'Наименование груза', width: 100 },
    { id: 'dateOfDeparture', name: 'Дата погрузки', dataType: 'date', type: 'date', width: 85 },
    { id: 'arrivalDate', name: 'Дата выгрузки', dataType: 'date', type: 'date', width: 85 },
    { id: 'accountNumber', name: '№ счета', dataType: 'number', width: 50 },
    { id: 'customer', name: 'Заказчик', width: 150 },
    { id: 'isHired', name: 'Статус привлечен.', type: 'check-mark', width: 50 },
    { id: 'carrier', name: 'Перевозчик', width: 150 },
    { id: 'carrierOrderNumber', name: 'Заявка-перевозчика №', width: 150 },
    { id: 'hiredCar', name: 'Автомобиль перевозчика', width: 150 },
    { id: 'hiredDriver', name: 'Водитель перевозчика', width: 150 },
    { id: 'car', name: 'Автомобиль', width: 150 },
    { id: 'loadingAddress', name: 'Адрес погрузки', width: 150 },
    { id: 'unloadingAddress', name: 'Адрес выгрузки', width: 150 },
    { id: 'paymentType', name: 'Форма оплаты', width: 60 },
    { id: 'weight', name: 'Вес/часы', dataType: 'number', width: 70 },
    { id: 'rate', name: 'Ставка', dataType: 'number', width: 70 },
    { id: 'routeCost', name: 'Стоимость', dataType: 'number', width: 70 },
    { id: 'driver', name: 'Водитель', width: 150 },
    { id: 'carrierPaymentType', name: 'Форма оплаты перевозчика', width: 60 },
    { id: 'driverSalary', name: 'Зарплата водителя', dataType: 'number', width: 70 },
    { id: 'debt', name: 'Задолженность', dataType: 'number', width: 70 },
    { id: 'carrierDocumentsReceived', name: 'Дата получения документов', dataType: 'date', type: 'date', width: 85 },
    { id: 'carrierDueDate', name: 'Срок оплаты перевозчику', dataType: 'date', type: 'date', width: 85 },
    { id: 'paymentDate', name: 'Дата оплаты', dataType: 'date', type: 'date', width: 85 },
    { id: 'paymentAmount', name: 'Сумма оплаты', dataType: 'number', width: 70 },
    { id: 'balance', name: 'Сальдо', dataType: 'number', width: 70 },
    { id: 'fine', name: 'Штраф', dataType: 'number', width: 70 },
    { id: 'comment', name: 'Комментарий', width: 150 },
    { id: 'lastModifiedBy', name: 'Кто изменил', width: 200 },
    { id: 'lastModified', name: 'Дата изменения', dataType: 'date', type: 'timestamp', width: 140 },
  ].map(header => {
    if (!header.width) {
      header.width = 100
    }
    if (!header.dataType) {
      header.dataType = 'string'
    }
    return header
  }), [])

  const initialScrollOffset = useMemo(() => {
    if (pageState.activeRowId) {
      const scrollToIndex = items.findIndex(item => item.id === pageState.activeRowId)
      if (scrollToIndex !== -1) {
        // Calculate the top position of the desired row
        const rowTopPosition = scrollToIndex * lineHeight

        // Get the height of the list container
        const listHeight = listWrapperRef.current ? listWrapperRef.current.clientHeight : 0

        // Calculate the center position
        const centerPosition = listHeight / 2

        // Adjust the scroll position to center the row and account for the header
        return Math.max(rowTopPosition - centerPosition + (lineHeight / 2) + headersHeight, 0)
      }
    } else if (items.length > 0) {
      return Math.ceil((items.length - 1) * lineHeight)
    }
    return 0
  }, [pageState.activeRowId, items, headersHeight, lineHeight])

  const setSort = useCallback(
    (header) => {
      const isSameKey = pageState.sort.key === header.id
      const newDirection = isSameKey ? !pageState.sort.direction : true
      dispatch({
        type: 'SET_SORT',
        page: pageName,
        payload: {
          key: header.id,
          direction: newDirection,
          dataType: header.dataType
        }
      })
    },
    [dispatch, pageName, pageState]
  )

  const setActiveRowId = useCallback(
    (id) => {
      dispatch({ type: 'SET_ACTIVE_ROW_ID', page: pageName, payload: id })
    },
    [dispatch, pageName]
  )

  const handleRowClick = useCallback((index) => {
    if (index === undefined) {
      return
    }
    if (index >= 0 && index < items.length) {
      setActiveIndex((prevState) => {
        if (prevState !== index) {
          setActiveRowId(items[index].id)
          return index
        }
        return prevState
      })
    } else {
      console.error('Invalid index:', index)
    }
  }, [items, setActiveIndex, setActiveRowId])

  const onSingleClick = useCallback(
    (index) => {
      const selection = window.getSelection()
      if (index === undefined || index === activeIndex || selection.toString().length > 1) {
        return
      }
      handleRowClick(index)
    }, [handleRowClick, activeIndex])

  const onDoubleClick = useCallback(() => {
    if (activeIndex !== -1) {
      router(`/${navigateTo}/edit/${items[activeIndex].id}`)
    }
  }, [router, items, activeIndex, navigateTo])

  const handleClick = useSingleAndDoubleClick(onSingleClick, onDoubleClick)

  //Right click menu
  const [contextMenuState, setContextMenuState] = useState(null)

  const handleRightClick = (index, position, item) => {
    handleRowClick(index)
    setContextMenuState({ position, item })
  }

  const closeContextMenu = () => {
    setContextMenuState(null)
  }

  const setListWrapperHeight = () => {
    const windowHeight = window.innerHeight
    if (listWrapperRef.current) {
      listWrapperRef.current.style.height = `${windowHeight - 78}px`
    }
  }

  useEffect(() => {
    setListWrapperHeight()
    window.addEventListener('resize', setListWrapperHeight)
    return () => {
      window.removeEventListener('resize', setListWrapperHeight)
    }
  }, [])

  useEffect(() => {
    if (listRefCurrent) {
      if (pageState.activeRowId) {
        const scrollToIndex = items.findIndex(item => item.id === pageState.activeRowId)
        setActiveIndex(scrollToIndex)
      }
    }
  }, [listRefCurrent, items, pageState.activeRowId, setActiveIndex])

  useEffect(() => {
    if (!listRefCurrent) return

    // Skip this effect on initial render
    if (isInitialRender.current) {
      isInitialRender.current = false
      return
    }

    // Use itemsLength state to get the current length
    if (items.length > 0) {
      setTimeout(() => {
        const lastIndex = items.length - 1
        setActiveIndex(-1) // Reset active index
        setActiveRowId('')
        listRefCurrent.scrollToItem(lastIndex, 'end')
      }, 0)
    }
  }, [listRefCurrent, filterQuery, items.length, setActiveRowId, setActiveIndex])

  return (
    <ListWrapper ref={listWrapperRef}>
      <AutoSizer>
        {({ height, width }) => (
          <StickyList
            ref={(el) => {
              refs.listRef.current = el
              setListRefCurrent(el)
            }}
            headerRef={refs.headerRef}
            setActiveIndex={setActiveIndex}
            setActiveRowId={setActiveRowId}
            setSort={setSort}
            sort={pageState.sort}
            height={height - 60}
            width={width}
            itemCount={items.length}
            itemSize={lineHeight}
            innerElementType={innerElementType}
            headers={headers}
            initialScrollOffset={initialScrollOffset}
            overscanCount={25}
          >
            {({ index, style }) => (
              <Row
                headers={headers}
                items={items}
                theme={theme}
                index={index}
                style={style}
                width={width - 10}
                isActive={index === activeIndex}
                onClick={(e) => {
                  if (e.target.tagName.toLowerCase() === 'a') {
                    return
                  }
                  e.preventDefault()
                  e.stopPropagation()
                  handleClick(index)
                }}
                filterQuery={filterQuery}
                onRightClick={handleRightClick}
                contextMenu={contextMenu}
                separateDateKey={separateDateKey}
              />
            )}
          </StickyList>
        )}
      </AutoSizer>
      {contextMenu && contextMenuState && (
        <ContextMenu
          listWrapperRef={listWrapperRef}
          position={contextMenuState.position}
          onClose={closeContextMenu}
          item={contextMenuState.item}
          path={path}
          request={pageName}
          label={contextMenuState.item[labelConfig.labelKey]
            ?
            (labelConfig.labelFormat === 'date'
              ?
              moment(contextMenuState.item[labelConfig.labelKey]).format('DD.MM.yyyy')
              :
              contextMenuState.item[labelConfig.labelKey])
            : '-'}
        />
      )}
    </ListWrapper>
  )
})

export default VirtualizedList