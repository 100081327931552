import React, { useCallback, useContext, useEffect, useState } from 'react'
import Library from '../../../Library'
import moment from 'moment'
import { ShowPayments } from './ShowPayments'
import { useDispatch } from 'react-redux'
import DateInput from '../../../components/Inputs/DateInput'
import Select from '../../../components/Inputs/Select'
import ModalContext from '../../../context/ModalContext'
import Button from '../../../components/Buttons/Button'
import styled from 'styled-components'
import TextInput from '../../../components/Inputs/TextInput'
import { useTheme } from '../../../context/ThemeContext'

const Wrapper = styled.div`
  margin: 15px 0px 0px 5px;
`

const InputsWrapper = styled.div`
  display: flex;
  margin: 25px 0 10px 0;
  height: 50px;
  gap: 10px;
  align-items: center;
  width: 1025px;
`

const PeriodInputWrapper = styled.div`
  min-width: 150px;
  max-width: 150px;
`

const DriverInputWrapper = styled.div`
  min-width: 250px;
  max-width: 250px;
`

const ResultInputWrapper = styled.div`
min-width: 130px;
max-width: 130px;
`

export const Filter = ({ routes, filteredRoutes, expenses, drivers, filterQuery, setFilterQuery, pageName }) => {
  const dispatch = useDispatch()
  const { theme } = useTheme()
  const { showModal, setModalContent, setModalPadding } = useContext(ModalContext)
  const [period, setPeriod] = useState('')
  const [dataReady, setDataReady] = useState(false)

  const filterQueryHandler = useCallback((newFilterQuery) => {
    setFilterQuery(newFilterQuery)
    dispatch({ type: 'SET_FILTER_QUERY', page: pageName, payload: newFilterQuery })
  }, [setFilterQuery, dispatch, pageName])

  const getSelectOptions = useCallback((data) => {
    let arr = [
      {
        value: '',
        label: '-',
        defaultValue: true,
        disabled: false,
      },
    ]
    if (data.length) {
      data.forEach((item) => {
        arr.push({ value: item.id, label: item.name })
      })
    }
    return arr
  }, [])

  const getDriverSelectOptions = useCallback(() => {
    let arr = [
      {
        value: '',
        label: '-',
        defaultValue: true,
        disabled: false,
      },
    ]
    if (routes.length) {
      const startDate = filterQuery.startDate
      const endDate = filterQuery.endDate
      let tmp = []
      routes.forEach((item) => {
        if (
          moment(item?.dateOfDeparture).isBetween(
            startDate,
            endDate,
            'days',
            '[]'
          ) &&
          item?.driver &&
          !tmp.includes(item.driver)
        ) {
          tmp.push(item.driver)
        }
      })
      if (tmp.length) {
        tmp.forEach((item) => {
          let label = Library.findNameByKey(drivers, item)
          arr.push({
            value: item,
            label: label ? label : '',
          })
        })
      }
      arr.sort((a, b) => a.label.localeCompare(b.label))
    }
    return arr
  }, [routes, drivers, filterQuery.startDate, filterQuery.endDate])

  const [driverOptions, setDriverOptions] = useState([])
  useEffect(() => {
    if (period) {
      setDriverOptions(getDriverSelectOptions())
    } else {
      setDriverOptions(getSelectOptions(drivers))
    }
  }, [routes, drivers, filterQuery.startDate, getDriverSelectOptions, getSelectOptions, period])

  const [paid, setPaid] = useState('')
  const [remaining, setRemaining] = useState('')
  const [accrued, setAccrued] = useState('')

  useEffect(() => {
    let newPeriod = ''
    if (filterQuery.startDate) {
      const year = `${new Date(filterQuery.startDate).getFullYear()}`
      const month = `${new Date(filterQuery.startDate).getMonth() + 1}`
      newPeriod = `${year}-${month.length < 2 ? '0' + month : month}`
      setPeriod(newPeriod)
    } else {
      setPeriod('')
    }
    let calculatedPaid = 0
    let calculatedAccrued = 0

    // Calculate accrued
    filteredRoutes.forEach((route) => {
      if (filterQuery.driver && route.driver === filterQuery.driver) {
        calculatedAccrued += parseFloat(route.driverSalary || 0)
      } else {
        calculatedAccrued += parseFloat(route.driverSalary || 0)
      }
    })

    // Calculate paid
    expenses.forEach((expense) => {
      const periodFilter = expense.period === newPeriod
      const driverFilter = expense.bind === filterQuery.driver
      const isDriver = expense.bindingType === 'Водитель'

      if (newPeriod && filterQuery.driver) {
        if (periodFilter && isDriver && driverFilter) {
          calculatedPaid += parseFloat(expense.amount || 0)
        }
      } else if (newPeriod && !filterQuery.driver) {
        if (periodFilter && isDriver) {
          calculatedPaid += parseFloat(expense.amount || 0)
        }
      } else if (!newPeriod && filterQuery.driver) {
        if (isDriver && driverFilter) {
          calculatedPaid += parseFloat(expense.amount || 0)
        }
      } else {
        if (isDriver) {
          calculatedPaid += parseFloat(expense.amount || 0)
        }
      }
    })

    // Calculate remaining
    const calculatedRemaining = calculatedAccrued - calculatedPaid

    setPaid(calculatedPaid)
    setRemaining(calculatedRemaining)
    setAccrued(calculatedAccrued)

    setDataReady(true)
  }, [filterQuery, filteredRoutes, expenses, routes])

  return (
    <Wrapper>
      {dataReady &&
        <InputsWrapper>
          {/* ------- PERIOD ------- */}
          <PeriodInputWrapper>
            <DateInput
              label='Период'
              name='period'
              id='period'
              type='month'
              value={period}
              onChange={(e) => {
                const str = e.target.value
                const startDate = str ? new Date(str.slice(0, 4), parseFloat(str.slice(-2)) - 1, 1) : ''
                const endDate = str ? new Date(str.slice(0, 4), parseFloat(str.slice(-2)), 0) : ''
                filterQueryHandler({
                  ...filterQuery,
                  startDate, endDate
                })
              }}
            />
          </PeriodInputWrapper>
          {/* ------- DRIVER ------- */}
          <DriverInputWrapper>
            < Select
              label='Водитель'
              id='driver'
              name='driver'
              value={filterQuery.driver}
              options={driverOptions}
              searchable={true}
              onChange={(e) => {
                filterQueryHandler({
                  ...filterQuery,
                  driver: e.target.value
                })
              }}
            />
          </DriverInputWrapper>
          {/* ------- ACCRUED ------- */}
          <ResultInputWrapper>
            <TextInput
              style={{ color: theme === 'light' ? 'red' : 'var(--red-lighten-color)' }}
              label='Начислено'
              name='accrued'
              id='accrued'
              value={accrued ? accrued.toLocaleString() + ' р.' : '0'}
              readOnly={true}
            />
          </ResultInputWrapper>
          {/* ------- PAID ------- */}
          <ResultInputWrapper>
            <TextInput
              style={{ color: theme === 'light' ? 'green' : 'var(--green-lighten-color)' }}
              label='Выплачено'
              name='paid'
              id='paid'
              value={paid ? paid.toLocaleString() + ' р.' : '0'}
              readOnly={true}
            />
          </ResultInputWrapper>
          {/* ------- REMAINING ------- */}
          <ResultInputWrapper>
            <TextInput
              style={{ color: theme === 'light' ? 'blue' : 'var(--blue-lighten-color)' }}
              label='Остаток'
              name='remaining'
              id='remaining'
              value={remaining ? remaining.toLocaleString() + ' р.' : '0'}
              readOnly={true}
            />
          </ResultInputWrapper>
          <Button
            style={{ minWidth: '110px' }}
            onClick={(e) => {
              e.preventDefault()
              setModalContent(<ShowPayments filterQuery={filterQuery} expenses={expenses} drivers={drivers} />)
              setModalPadding({ smallScreen: '30px 5px 20px 5px;', largeScreen: '25px' })
              showModal(true)
            }}
            title='Показать выплаты'
          >
            ВЫПЛАТЫ
          </Button>
          {/* ------- CLEAR BUTTON ------- */}
          <Button
            style={{ display: filterQuery.driver || filterQuery.startDate ? 'block' : 'none', backgroundColor: 'var(--alert-button-bg)', minWidth: '110px' }}
            onClick={(e) => {
              e.preventDefault()
              filterQueryHandler({
                startDate: '',
                endDate: '',
                driver: '',
              })
              setPeriod('')
              setPaid('')
              setRemaining('')
              setAccrued('')
            }}
            title='Очистить фильтр'
          >
            ОЧИСТИТЬ
          </Button>
        </InputsWrapper>
      }
    </Wrapper >
  )
}